import { Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

export const TextParagraph = withStyles({
  root: {
    color: "#FFFFFF",
    paddingBlockEnd: "0.5rem",
    paddingBlockStart: "0.5rem",
    lineHeight: "1.2em",
  }
})(Typography);
