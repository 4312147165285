import React from "react";
import { CheckCircle, Cancel } from "@material-ui/icons";
import { TitleText } from "../title-text/title-text";

import './sample-image.css';

export const SampleImage = ({imgUrl, width = "200px", height = "unset", bad, good, explanationText}) => (
  <div class="container" style={{ width, height }}>
    <TitleText style={{textAlign: 'center'}}>{explanationText}</TitleText>
    <img
      src={imgUrl}
      width="100%"
      height="60%"
      alt={ bad && !good ? 'Example of a bad image' : 'Example of a good photo' }
    />
    <div class="icon">
      { bad && !good && <Cancel id="bad"></Cancel>}
      { !bad && <CheckCircle id="good"/>}
    </div>
  </div>
);

SampleImage.propTypes = {};
SampleImage.defaultProps = {};
