import React from 'react';
import PropTypes from 'prop-types';

import './analysis-animation.css';

export const AnalysisAnimation = () => (
  <div id="logo">
    <div class="brand-a padded"></div>
    <div class="brand-b padded"></div>
    <div class="brand-c padded"></div>
  </div>
);

AnalysisAnimation.propTypes = {};

AnalysisAnimation.defaultProps = {};
