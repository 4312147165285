/**
 * Converts raw image data into an uploadable buffer
 *
 * @param {string} rawImageString image data in form `data:image/jpeg;base64,`
 * @return {Buffer} ready for upload via HTTP 
 */
export function convertBase64ImageIntoUploadable(rawImageString) {
  return new Buffer.from(
    rawImageString.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
}
