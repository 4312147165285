import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { TextParagraph } from "../title-text/branded-text";
import { ActionButton } from "../action-button/action-button";

import './recognition.css';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};

const Recognition = ({
  textBelow,
  onUpload,
  allowUpload = false,
}) => {
  const webcamRef = useRef(null);
  const [imgRaw, setUploadCandidate] = useState(null);

  /**
   * Takes a screenshot from the webcam feed, storing it on .imgRaw
   *
   */
  const capturePhoto = () => {
    const imgRaw = webcamRef.current.getScreenshot();
    setUploadCandidate(imgRaw);
  };

  /**
   * Call the provided onUpload() by providing the base64 image data
   *
   */
  const triggerUpload = () => {
    if (allowUpload && onUpload) {
      onUpload(imgRaw);
    } else {
      console.warn('Preventing upload due to consent');
    }
  };

  /**
   * Clears .imgRaw
   *
   */
  const purgeSnapshot = () => {
    setUploadCandidate(null);
  };

  /**
   * Contents to show if we need user to take a new photo
   */
  const takeNewPhotoScreen = (
    <div class="recog-container">
      <div id="webcam">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{
            objectFit: "contain",
            width: '100%',
          }}
          minScreenshotHeight={2000}
          screenshotQuality={0.7}
        />
      </div>
      <div class="below live">
        <div class="webcam-text">
          <TextParagraph>{textBelow}</TextParagraph>
        </div>
        <div class="webcam-actions">
          <ActionButton onClick={capturePhoto}>Take Photo</ActionButton>
        </div>
      </div>
    </div>
  );

  /**
   * Contents to show if we need user to confirm a previously taken photo
   */
  const confirmPhotoScreen = (
    <div class="recog-container">
      <div id="stillframe">
        <img id="photo-confirm" src={imgRaw} alt="Your hardware" style={{
          objectFit: "contain",
          width: "100%",
          display: "block",
          alignSelf: "center",
        }} />
      </div>
      
      <div class="below confirm">
        <div class="actions">
          <ActionButton onClick={triggerUpload}>Confirm photo</ActionButton>
          <ActionButton onClick={purgeSnapshot} variant="secondary">Take another photo</ActionButton>
        </div>
      </div>
    </div>
  )

  return (
    <div class="component">
      {!!imgRaw ? confirmPhotoScreen : takeNewPhotoScreen}
    </div>
  );
};

export default Recognition;
