import React, { useState, useLayoutEffect } from "react";
import "./App.css";
import Recognition from "./components/recognition/recognition";
import { CssBaseline, Container, Box } from "@material-ui/core";
import { TitleTextBold } from "./components/title-text/title-text-bold";
import { powersensorLogoURL } from "./constants";
import { getUploadUrl, getPrediction, uploadData, safelyExtractPrediction } from "./api";
import { convertBase64ImageIntoUploadable } from './imageData';
import { ActionButton } from "./components/action-button/action-button";
import { AnalysisAnimation } from './components/analysis-animation/analysis-animation';
import { SampleImage } from "./components/sample-image/sample-image";
import { TextParagraph } from "./components/title-text/branded-text";
import { appEvents, logAppEvent } from "./telemetry/firebase";

export const PAGES = {
  WELCOME: 'page_welcome',
  PERMISSION: 'page_ask_permission',
  UPLOAD_METER: 'page_upload_meter',
  ASK_INVERTER: 'page_ask_inverter',
  UPLOAD_INVERTER: 'page_upload_inverter',
  THANKS: 'page_thanks',
  PROCESSING: 'page_processing',
  SAD_METER_UPLOAD: 'page_bad_meter_upload',
  SAD_INVERTER_UPLOAD: 'page_bad_inverter_upload',
  SUCCESS_INVERTER_UPLOAD: 'page_good_inverter_upload',
  SUCCESS_METER_UPLOAD: 'page_good_meter_upload',
  INSTRUCTIONS: 'page_instructions',
  NO_CONSENT: 'page_no_permission',
  FAIL_UPLOAD: 'page_upload_failed',
}

const App = ({ pageNumber = PAGES.WELCOME }) => {
  const [stage, setStage] = useState(pageNumber);
  const [allowPhotos, setAllowPhotos] = useState(true);
  const [size, storeWindowSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      storeWindowSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const uploadMeterBoardImage = async (imageData) => {
    navToPage(PAGES.PROCESSING);
    let predictionResult;

    try {
      const { url, path: s3Path } = await getUploadUrl('meter');
      const uploadableData = convertBase64ImageIntoUploadable(imageData);
      const success = await uploadData(url, uploadableData);
      if (!success) {
        throw Error('Failed to upload');
      }
      predictionResult = await getPrediction(s3Path);
    } catch (error) {
      console.error('upload failed %o', error);
      navToPage(PAGES.FAIL_UPLOAD);
    }

    if (predictionResult) {
      if (safelyExtractPrediction(predictionResult) === 'Irrelevant') {
        navToPage(PAGES.SAD_METER_UPLOAD);
      } else {
        navToPage(PAGES.SUCCESS_METER_UPLOAD);
      }
    }
  };

  const uploadInverterImage = async (imageData) => {
    navToPage(PAGES.PROCESSING);
    let predictionResult;
    try {
      const { url, path: s3Path } = await getUploadUrl('inverter');
      const uploadableData = convertBase64ImageIntoUploadable(imageData);
      const success = await uploadData(url, uploadableData);
      if (!success) {
        throw Error('Failed to upload');
      }
      predictionResult = await getPrediction(s3Path);
    } catch (error) {
      console.error('upload failed %o', error);
      navToPage(PAGES.FAIL_UPLOAD);
    }

    if (predictionResult) {
      if (safelyExtractPrediction(predictionResult) === 'Irrelevant') {
        navToPage(PAGES.SAD_INVERTER_UPLOAD);
      } else {
        navToPage(PAGES.SUCCESS_INVERTER_UPLOAD);
      }
    }
  };

  /**
   * Logs and navigates to a new page
   *
   * @param {string} targetPage
   */
  const navToPage = (targetPage) => {
    logAppEvent(appEvents.NAVIGATE_PAGE(stage, targetPage));
    setStage(targetPage);
  }

  const renderSwitch = (pageIdentifier) => {
    const [dynamicWidth, dynamicHeight] = size;
    switch (pageIdentifier) {
      case PAGES.WELCOME:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <img
              class="logo heading"
              src={powersensorLogoURL}
              alt="powersensor"
            />
            <div class="pageContent">
              <TitleTextBold variant="h5">
                Help us learn about your home's energy setup
              </TitleTextBold>
              <TextParagraph>
                Use this tool to send us a photo of your setup and help us improve our product.
              </TextParagraph>
            </div>
            <ActionButton onClick={() => navToPage(PAGES.PERMISSION)}>
              Get started
            </ActionButton>
          </div>
        );
      case PAGES.PERMISSION:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h5">
              Do you allow Powersensor to capture and upload photos?
            </TitleTextBold>
            <div class="actions">
              <ActionButton
                onClick={() => {
                  setAllowPhotos(true);
                  navToPage(PAGES.INSTRUCTIONS);
                }}>
                Yes
              </ActionButton>
              <ActionButton
                variant="secondary"
                onClick={() => {
                  setAllowPhotos(false);
                  navToPage(PAGES.NO_CONSENT);
                }}>
                No
              </ActionButton>
            </div>
          </div>
        );

      case PAGES.UPLOAD_METER:
        return (
          <div
            class="webcam-page"
            id={pageIdentifier}
            style={{height: `${dynamicHeight}px`}}>
            <Recognition
              header="Meter Board"
              textBelow="Ensure your full meter board is in view."
              onUpload={uploadMeterBoardImage}
              allowUpload={allowPhotos}
            />
          </div>

        );
      case PAGES.ASK_INVERTER:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h5">
              Do you have a solar inverter?
            </TitleTextBold>
            <div class="actions">
              <ActionButton onClick={() => navToPage(PAGES.UPLOAD_INVERTER)}>
                Yes
              </ActionButton>
              <ActionButton variant="secondary" onClick={() => navToPage(PAGES.THANKS)}>
                No
              </ActionButton>
            </div>
          </div>
        );

      case PAGES.UPLOAD_INVERTER:
        return (
          <div 
            class="webcam-page"
            id={pageIdentifier}
            style={{height: `${dynamicHeight}px`}}>
            <Recognition
              textBelow="Ensure your full solar inverter and isolator switches are in view."
              header="Solar Inverter"
              onUpload={uploadInverterImage}
              allowUpload={allowPhotos}
            />
          </div>
        );

      case PAGES.THANKS:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <img
              class="logo heading"
              src={powersensorLogoURL}
              alt="powersensor"
            />

            <div class="pageContent">
              <TitleTextBold variant="h5">
                Thank you
              </TitleTextBold>
              <TextParagraph>
                Your photos will help us to make Powersensor even smarter!
              </TextParagraph>
            </div>
          </div>
        );
      case PAGES.PROCESSING:
        return (
          <div className="centeredPage" id={pageIdentifier}>
            <div class="centeredContent">
              <div class="progress-animation" style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
              }}>
                <AnalysisAnimation />
                <TitleTextBold style={{ paddingBlockStart: '2rem' }}>Processing...</TitleTextBold>
              </div>
            </div>
          </div>
        );
      case PAGES.SAD_METER_UPLOAD:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h3">Sorry :(</TitleTextBold>
            <div class="pageContent">
              <TitleTextBold variant="h5">We couldn't recognise the meter board</TitleTextBold>
              <TextParagraph>Please make sure to take a photo of the whole board.</TextParagraph>
            </div>
            <div class="actions">
              <ActionButton onClick={() => navToPage(PAGES.UPLOAD_METER)}>Try Again</ActionButton>
              <ActionButton onClick={() => navToPage(PAGES.ASK_INVERTER)} variant="secondary">Use this photo</ActionButton>
            </div>
          </div>
        );
      case PAGES.SUCCESS_METER_UPLOAD:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h3">Great!</TitleTextBold>
            <div class="pageContent">
              <TitleTextBold variant="h5">A photo of your meter board has been captured.</TitleTextBold>
            </div>
            <ActionButton onClick={() => navToPage(PAGES.ASK_INVERTER)}>Continue</ActionButton>
          </div>
        );
      case PAGES.SAD_INVERTER_UPLOAD:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h3">Sorry :(</TitleTextBold>
            <div class="pageContent">
              <TitleTextBold variant="h5">We couldn't recognise the inverter.</TitleTextBold>
              <TextParagraph>Please make sure to take a photo of the whole inverter.</TextParagraph>
            </div>
            <div class="actions">
              <ActionButton onClick={() => navToPage(PAGES.UPLOAD_INVERTER)}>Try Again</ActionButton>
              <ActionButton onClick={() => navToPage(PAGES.THANKS)} variant="secondary">Use this photo</ActionButton>
            </div>
          </div>
        );
      case PAGES.SUCCESS_INVERTER_UPLOAD:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h3">Great!</TitleTextBold>
            <div class="pageContent">
              <TitleTextBold variant="h5">A photo of your inverter has been captured.</TitleTextBold>
            </div>
            <ActionButton onClick={() => navToPage(PAGES.THANKS)}>Continue</ActionButton>
          </div>
        );
      case PAGES.NO_CONSENT:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <div class="pageContent">
              <TitleTextBold variant="h5">This feature requires you to upload photos.</TitleTextBold>
              <TextParagraph>These photos will be processed to improve our service offering.</TextParagraph>
            </div>
            <div class="actions">
              <ActionButton onClick={() => navToPage(PAGES.PERMISSION)}>I've changed my mind</ActionButton>
            </div>
          </div>
        );
      case PAGES.INSTRUCTIONS:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <div class="pageContent">
              <TitleTextBold variant="h5">Take a photo of your setup</TitleTextBold>
              <TextParagraph>
                Please take a photo of your meter board and, if you have solar, your solar inverter. We will guide you through the process.
              </TextParagraph>
              <div class="images-horizontal">
                <SampleImage imgUrl="/samples/sample-bad.png" width="30vmin"
                  bad explanationText="Too close" />
                <SampleImage imgUrl="/samples/sample-good.png" width="30vmin"
                  explanationText="Just right" />
              </div>
            </div>
            <div class="actions">
              <ActionButton onClick={() => navToPage(PAGES.UPLOAD_METER)}>Continue</ActionButton>
            </div>
          </div>
        );
      case PAGES.FAIL_UPLOAD:
        return (
          <div className="scrollablePage" id={pageIdentifier}>
            <TitleTextBold variant="h3">:(</TitleTextBold>
            <div class="pageContent">
              <TitleTextBold variant="h5">There was an unexpected error while trying to upload your photo.</TitleTextBold>
              <TextParagraph>Please ensure you have a good internet connection and try again.</TextParagraph>
            </div>
            <ActionButton onClick={() => navToPage(PAGES.INSTRUCTIONS)}>Try Again</ActionButton>
          </div>
        );
      default:
        return (
          <div className="scrollablePage" id='default-page'>
            <div class="pageContent">
              <TitleTextBold variant="h3">Oh no!</TitleTextBold>
              <TextParagraph>There was an unexpected error.</TextParagraph>
            </div>
            <ActionButton onClick={() => navToPage(PAGES.WELCOME)}>Start Over</ActionButton>
          </div>
        );
    }
  };

  return (
    <>
      <CssBaseline />
      <main>
        <Container maxWidth="sm">
          <Box class="pageContainer">
            {renderSwitch(stage)}
          </Box>
        </Container>
      </main>
    </>
  );
};

export default App;
