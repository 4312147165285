import { initializeApp } from "firebase/app";
import * as firebase from "@firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCHhXY6Tivj7k0j79GUKY2I7RIyG-6ckcU",
  authDomain: "powersensor-ar-spike.firebaseapp.com",
  projectId: "powersensor-ar-spike",
  storageBucket: "powersensor-ar-spike.appspot.com",
  messagingSenderId: "154188996457",
  appId: "1:154188996457:web:5dc511f8ff3d019be1e7f8",
  measurementId: "G-XQ2712M8VS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = firebase.getAnalytics(app);

/**
 * Log rich events to firebase
 *
 * @param {{eventName: string, params: {}}} _
 */
export function logAppEvent({ eventName, params }) {
  if (process.env.NODE_ENV !== 'production') {
    return console.log(`logAppEvent({ eventName: ${eventName} }) stubbed for offline`);
  }
  try {
    firebase.logEvent(analytics, eventName, params || {});
  } catch (error) {
    firebase.logEvent(analytics, 'logger-error', { intendedEventName: eventName, params });
  }
}

export const appEvents = {
  INIT_APP: () => ({ eventName: 'application-init', params: {} }),
  NAVIGATE_PAGE: (fromPage, toPage) => ({ eventName: 'page-navigation', params: { fromPage, toPage } }),
  HANDLED_ERROR: (error) => ({ eventName: 'handled-error', params: { error }}),
}
