import { default as axios } from 'axios';
import * as constants from './constants';

/**
 * Ask the API for an upload URL where we can push an image to for given a given tag
 *
 * @param {string} tag
 * @return {{url: string, path: string}} an upload url, and the s3 path it will upload to 
 */
export async function getUploadUrl(tag) {
  try {
    const URL = constants.getUploadUrlEndpoint;
    const response = await axios.get(URL, { params: { tag } });
    return response.data;
  } catch (error) {
    throw Error('Failed to get upload url');
  }
}

/**
 * Uploads prepared data to s3
 *
 * @param {string} uploadURL presigned upload URL that accepts PUT
 * @param {Buffer} data prepared binary data to upload
 * @return {boolean} whether the upload was successful 
 */
export async function uploadData(uploadURL, data) {
  try {
    const response = await axios.put(uploadURL, data);
    return response.statusText === 'OK';
  } catch (error) {
    throw Error('Failed to upload data');
  }
}

/**
 * Asks the API for a prediction of a given file
 *
 * @param {string} imgUri path to uploaded file in form s3://bucket/path.jpeg
 * @return {*} raw preditcion response
 */
export async function getPrediction(imgUri) {
  try {
    const response = await axios.post(constants.getPredictionEndpoint, { path: imgUri });
    return response.data;
  } catch (error) {
    throw Error('Failed to retrieve results');
  }
}

/**
 * Takes a raw prediction API response and extracts the dominant label
 *
 * @param {*} rawPrediction
 * @return {string} predicted label 
 */
export function extractPrediction(rawPrediction) {
  if (!rawPrediction || !rawPrediction.predictions) {
    throw Error('ValueError');
  }
  const filesWithPredictions = Object.getOwnPropertyNames(rawPrediction.predictions);
  if (filesWithPredictions.length > 1) {
    throw Error('ValueError');
  }
  const prediction = rawPrediction.predictions[filesWithPredictions[0]];
  return prediction.label;
}

/**
 * Same as extractPrediction, but returns null if an issue is encountered. Appropriate
 * if an error can be swallowed here.
 *
 * @param {*} rawPrediction
 * @return {string} predicted label 
 */
export function safelyExtractPrediction(rawPrediction) {
  try {
    return extractPrediction(rawPrediction);
  } catch (error) {
    return null;
  }
}
