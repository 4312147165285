import { Button } from "@material-ui/core";
import React from 'react';

export const ActionButton = (props = {}) => {
  if (props.variant === 'secondary') {
    return (
      <Button onClick={props.onClick} {...{props}} variant="outlined" style={{color: 'white', borderColor: 'white'}}>{props.children}</Button>
    )
  } else {
    return (
      <Button onClick={props.onClick} {...{props}} variant="contained" style={{backgroundColor: 'white'}}>{props.children}</Button>
    )
  }
}

ActionButton.propTypes = {};
ActionButton.defaultProps = {};
